import React, { useState } from "react";
import { Link } from "gatsby";
import logoimg from "../images/SUNRISE_LOGO.png";
import styled from "styled-components";

const Logo = styled.img`
  width: 200px;
`;

const Nav = ({ openModal }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMenuclick = () =>
    showMobileMenu ? setShowMobileMenu(false) : setShowMobileMenu(true);

  return (
    <nav id="header" className="fixed w-full z-30 top-0 text-white shadow-md">
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <Link
            to="/"
            className="toggleColour text-sunrise-orange-1 bg-white no-underline hover:no-underline font-bold text-2xl lg:text-3xl"
          >
            <Logo src={logoimg} alt="Sunrise Speech and Swallowing Therapy" />
          </Link>
        </div>
        <div className="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            onClick={() => handleMenuclick()}
            className="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="https://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <div
          className={`w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
          id="nav-content ${!showMobileMenu ? "hidden" : ""}`}
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li className="mr-3">
              <Link
                to="/referrals"
                onClick={() => handleMenuclick()}
                className="inline-block text-sunrise-blue no-underline text-lg hover:text-sunrise-orange-1 hover:underline py-2 px-4"
              >
                Referrals
              </Link>
            </li>
            <li className="mr-3">
              <Link
                to="/who-we-are"
                onClick={() => handleMenuclick()}
                className="inline-block text-sunrise-blue no-underline text-lg hover:text-sunrise-orange-1 hover:underline py-2 px-4"
              >
                Who We Are
              </Link>
            </li>
            <li className="mr-3">
              <Link
                to="/services"
                onClick={() => handleMenuclick()}
                className="inline-block text-sunrise-blue no-underline text-lg hover:text-sunrise-orange-1 hover:underline py-2 px-4"
              >
                Services
              </Link>
            </li>
            <li className="mr-3">
              <Link
                to="/contact-us"
                className="inline-block text-sunrise-blue no-underline text-lg hover:text-sunrise-orange-1 hover:underline py-2 px-4"
                href="#"
              >
                Contact Us
              </Link>
            </li>
            <li
              className="mr-3"
              onClick={() => {
                openModal();
                handleMenuclick();
              }}
            >
              <span className="inline-block text-sunrise-blue no-underline text-lg hover:text-sunrise-orange-1 hover:underline py-2 px-4">
                Payment Methods
              </span>
            </li>
          </ul>
        </div>
      </div>
      <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  );
};

export default Nav;
