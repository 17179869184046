import React from "react";
import logoimg from "../images/SUNRISE_LOGO.png";
import venmoLogo from "../images/venmo-icon.svg";
import paypalLogo from "../images/PayPal-Symbol.png";

function PaymentModal({ closeModal }) {
  return (
    <>
      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          {/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    --> */}
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="mx-auto flex items-center justify-center w-full mb-5">
                <img className="w-1/2" src={logoimg} alt="Sunrise Logo" />
                {/* <!-- Heroicon name: outline/exclamation --> */}
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-2xl leading-6 font-medium text-gray-900 underline mb-6 text-center"
                  id="modal-headline"
                >
                  Payment Methods
                </h3>
                <div className="mt-2">
                  {/* <p className="mb-3">Sunrise Speech accepts:</p> */}
                  <ul>
                    <li className="flex items-center justify-items-center mb-4">
                      <img className="w-8 mr-2" src={venmoLogo} />
                      <div className="text-left">
                        @Shelby-Bjorlieatsunrise_speech
                      </div>
                    </li>
                    <li className="flex items-center justify-items-center mb-4">
                      <img className="w-8 mr-2" src={paypalLogo} />
                      <div className="text-left">
                        @ Sunrise Speech and Swallowing Therapy, PLLC
                      </div>
                    </li>
                    <li className="mb-4 text-left">
                      Cash, check, credit card and Zelle through Wells Fargo
                    </li>
                  </ul>
                  <p>
                    Medicare and health insurance billing are also available.{" "}
                    <br />
                    Please contact Sunrise Speech to verify payment eligibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                onClick={closeModal}
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentModal;
